<template>
    <div class="col-md-12 mt-2 mb-1">
        <div class="row row1">
            <div @click="isenabledMethod" class="name-div hand">
               <div class="pt-2 pr-2" style="cursor: pointer">
                   <!--<i class="fa fa-minus-circle" v-if="isenabled"></i>-->
                   <img class="" src="../../../assets/images/Down.svg" v-if="isenabled">
                   <img class="" src="../../../assets/images/Up.svg" v-else>
                   <!--<i class="fa fa-plus-circle"></i>-->
               </div>
                <h3>{{name}}</h3>
            </div>
            <div class="time-div col2">
                <h3 class="pull-right" title="Hours:Minutes">
                    {{min_to_hour(project.project_total_time)}}</h3>
            </div>
        </div>
        <div class="row" v-if="isenabled">
            <member :id="index" :key="index" :projectId="project.project_id" :member="member" v-for="(member, index) in project.members" />
        </div>
    </div>
</template>

<script>

    import { min_to_hour } from '@/assets/js/utils'

    export default {
        name : "project",
        components : { member : () => import('../../../components/reports/reportPage/members') },
        data () {
            return {
                isenabled : true
            }
        },
        props : [ 'project', 'name' ],
        mounted () {
            this.$root.$on ( 'reset_report_collapse', () => {
                this.isenabled = true
            } )
        },
        methods : {
            isenabledMethod () {
                this.isenabled = !this.isenabled;
            },
            min_to_hour ( minutes ) {
                let time = min_to_hour ( minutes );
                let hrs = time.hrs;
                let mins = time.mins;
                if ( hrs <= 9 ) {

                    hrs = "0" + time.hrs
                }
                if ( mins <= 9 ) {
                    mins = "0" + time.mins
                }
                return hrs + ":" + mins
            }
        }
    }
</script>

<style scoped>
    .row1 {
        border          : 0px;
        padding-right   : 0px !important;

        white-space     : nowrap;
        border-bottom   : 1px solid #D6E7FF;
        display         : flex;
        justify-content : space-between;
        align-items     : center;
    }

    .col2 {
        padding-right : 0px !important;
    }

    .time-div {
        padding      : 15px;
        padding-left : 0px !important;
    }

    .name-div.hand {
        text-align : left;
        width      : 78%;
        display: inherit;
    }

    h3 {
        padding-top   : 4px;
        margin-bottom : 0px;
        font-size     : large;
        font-weight   : 500;
    }

    .hand {
        -webkit-touch-callout : none; /* iOS Safari */
        -webkit-user-select   : none; /* Safari */
        -khtml-user-select    : none; /* Konqueror HTML */
        -moz-user-select      : none; /* Firefox */
        -ms-user-select       : none; /* Internet Explorer/Edge */
        user-select           : none;
        display: flex;
        /* Non-prefixed version, currently
                                        supported by Chrome and Opera */
    }

    .hand span {
        padding-right : 7px;
        padding-top   : 2px;
    }

    .hand span i {
        font-size : large;
    }
    img.img-icon {
        width  : 15px;
        height : 15px;
    }
    @media (max-width : 480px) {
        .time-div::before {
            content      : "";
            border-right : 1px solid #D6E7FF;
            padding      : 13px 0px;
            right        : 50px;
            top          : 15px;
            position     : absolute;
        }
    }
    @media (max-width : 320px) {
        .time-div::before {
            right : 39px;
        }

        .time-div h3 {
            font-size : 14px;
        }
    }
</style>
