<template>
  <div>
    <v-row class="mt-4 d-flex justify-end" @click="menu = false">
      <!-- Date Range Picker -->
      <v-col cols="12" sm="6" md="2" class="mt-n1">
        <label
          class="labels ml-4 d-block font-weight-thin text-left"
          for
          id="cRange"
          >Date Range:</label
        >
        <v-menu id="list-menu" nudge-left="0" :close-on-click="false" offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-col class="d-flex flex-row-reverse">
              <v-subheader
                class="selected-date"
                v-bind="attrs"
                v-on="on"
                style="width: 100%"
                @click="checkClick"
              >
                {{ dateRangeTitle }}
                <span class="d-flex justify-start ml-auto">
                  <v-icon
                    color="primary"
                    id="rangeBtn"
                    class="mdi-dark mdi-inactive"
                    >mdi-calendar-range</v-icon
                  >
                </span>
              </v-subheader>
            </v-col>
          </template>
          <v-list id="custom-list-style">
            <v-list-item
              style="min-height: 30px"
              v-for="(item, index) in items"
              :key="index"
              @click="checkIfCustomDate(item)"
            >
              <v-list-item-title style="font-size: 13px" class="custom">{{
                item.title
              }}</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
        <v-dialog
          ref="dialog"
          v-model="modal_2"
          :return-value.sync="date"
          width="290px"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-text-field
              v-show="(modal_2_show = false)"
              v-model="date"
              label="Picker in dialog"
              prepend-icon="mdi-calendar"
              readonly
              v-bind="attrs"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker v-model="date" range no-title scrollable>
            <v-spacer></v-spacer>
            <v-btn text color="primary" @click="closeDateModal()">
              Cancel
            </v-btn>
            <v-btn
              text
              color="primary"
              id="apply-btn"
              @click="getreportFromCustomDate(), selectedDateTeamMembers()"
            >
              Apply
            </v-btn>
          </v-date-picker>
        </v-dialog>
      </v-col>
      <!-- Team Member Dropdown -->
      <v-col
        cols="12"
        sm="6"
        md="2"
        v-if="
          (selectedCompanyUserStatus != 'employee' && reportsUserStatus) ||
          teamLeadStatus > 0
        "
      >
        <v-autocomplete
          v-if="dataForReport"
          outlined
          dense
          v-model="selectedMember"
          :items="propMember"
          item-text="name"
          item-value="id"
          label="Search By Members"
          class="mt-4"
        ></v-autocomplete>
        <v-autocomplete
          v-else
          outlined
          dense
          v-model="selectedMember"
          :items="teamMember"
          :loading="teamMemberLoader"
          item-text="name"
          item-value="id"
          label="Search By Members"
          class="mt-4"
        ></v-autocomplete>
      </v-col>
      <!-- Projects Dropdown -->
      <v-col
        cols="12"
        sm="6"
        md="2"
        v-if="
          (selectedCompanyUserStatus != 'employee' && reportsUserStatus) ||
          teamLeadStatus > 0
        "
      >
        <v-autocomplete
          outlined
          dense
          v-model="selectedProject"
          :items="sortProjects"
          item-text="project_name"
          item-value="id"
          label="Search By Project"
          class="mt-4"
        ></v-autocomplete>
      </v-col>
      <!-- Department Dropdown -->
      <v-col
        cols="12"
        sm="6"
        md="2"
        v-if="selectedCompanyUserStatus != 'employee' && reportsUserStatus"
      >
        <v-autocomplete
          outlined
          dense
          v-model="selectedDepartment"
          :items="allDepartmentList"
          item-text="name"
          item-value="hash_id"
          label="Search By Department"
          class="mt-4"
        ></v-autocomplete>
      </v-col>
      <!-- Export Button -->
      <v-col
        cols="6"
        sm="6"
        md="2"
        class="mt-5"
        v-if="
          !Object.keys(reports).length == 0 &&
          selectedCompanyUserStatus != 'employee'
        "
      >
        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              color="#2758f6"
              dark
              v-bind="attrs"
              v-on="on"
              class="mt-n1 px-6 px-sm-16 px-md-5"
            >
              Export As
            </v-btn>
          </template>
          <v-list>
            <v-list-item link>
              <v-list-item-title align="left">
                <download-csv
                  :data="csvDownload.data"
                  :fields="csvDownload.fields"
                  :labels="csvDownload.labels"
                  :name="csvDownload.name"
                  >CSV
                </download-csv>
              </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title align="left">
                <download-excel
                  :data="excelDownload.data"
                  :labels="excelDownload.labels"
                  :name="excelDownload.name"
                  worksheet="abc"
                  >Excel
                </download-excel>
              </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title align="left">
                <download-csv
                  :data="accumulatedCsvDownload.data"
                  :fields="accumulatedCsvDownload.fields"
                  :labels="accumulatedCsvDownload.labels"
                  :name="accumulatedCsvDownload.name"
                  >Accumulated CSV
                </download-csv>
              </v-list-item-title>
            </v-list-item>
            <v-list-item link>
              <v-list-item-title align="left">
                <download-excel
                  :data="accumulatedExcelDownload.data"
                  :fields="accumulatedExcelDownload.fields"
                  :name="accumulatedExcelDownload.name"
                  >Accumulated Excel
                </download-excel>
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>
      <!-- Search Button -->
      <v-col
        cols="6"
        sm="6"
        :md="selectedCompanyUserStatus != 'employee' ? '2' : '3'"
      >
        <v-btn
          primary
          class="active-custom-btn mt-4 px-10 px-sm-16 px-md-5 px-lg-16"
          @click="getReportsData()"
        >
          <span class="mdi">Search</span>
        </v-btn>
      </v-col>
    </v-row>
    <v-row class="ml-sm-15 mr-sm-15">
      <v-col cols="12">
        <!-- Render If No Reports Found -->
        <div class="row" v-if="!reports || reports.length === 0">
          <div class="col-md-12 mt-4">
            <img
              class="error-img"
              src="../../assets/images/no-activity-found-new.svg"
            />
            <p
              class="error_text"
              v-if="!getData && selectedCompanyUserStatus !== 'employee'"
            >
              Please select the team member and press search button
            </p>
            <p class="error_text" v-else>No Data Found</p>
          </div>
        </div>
        <!-- Reports Project Component -->
        <div class="mt-5 main-reports">
          <div class="border">
            <div class="row break">
              <reportProject
                :key="index"
                :name="index"
                :project="project"
                ref="reportProject"
                v-for="(project, index) in reports"
              />
            </div>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import moment from "moment";
import { mapState } from "vuex";
import { min_to_hour } from "../../assets/js/utils";
import JsonCSV from "vue-json-csv";
import JsonExcel from "vue-json-excel";
import reportProject from "../../components/reports/reportPage/project";
export default {
  name: "report",
  components: {
    reportProject: reportProject,
    "download-csv": JsonCSV,
    downloadExcel: JsonExcel,
  },
  props: ["dataForReport"],
  data() {
    return {
      menu_2: false,
      modal_2: false,
      teamMemberLoader: false,
      dateRangeTitle: "",
      items: [
        { id: 1, title: "Today" },
        { id: 2, title: "Yesterday" },
        { id: 3, title: "Last 7 Days", value: "" },
        { id: 4, title: "Last 30 Days", value: "" },
        { id: 5, title: "This Month", value: "" },
        { id: 6, title: "Last Month", value: "" },
        { id: 7, title: "Last Year", value: "" },
        { id: 8, title: "Custom Range" },
      ],
      tabIndex: 0,
      csv_fields: {
        details: {
          labels: {
            project_name: "Projects",
            name: "Members",
            date: "Date",
            task: "Task",
            time_worked: "Hours Worked",
            description: "Description",
          },
          fields: [
            "project_name",
            "name",
            "date",
            "task",
            "time_worked",
            "description",
          ],
          dataFile:
            "reports (" +
            this.$store.state.custom.start_date +
            " - " +
            this.$store.state.custom.end_date +
            ").csv",
        },
        members: {
          labels: {
            name: "Members",
            time_worked: "Hours Worked",
          },
          fields: ["name", "time_worked"],
          dataFile:
            "members (" +
            this.$store.state.custom.start_date +
            " - " +
            this.$store.state.custom.end_date +
            ").csv",
        },
        projects: {
          labels: {
            project_name: "Projects",
            time_worked: "Hours Worked",
          },
          fields: ["project_name", "time_worked"],
          dataFile:
            "projects (" +
            this.$store.state.custom.start_date +
            " - " +
            this.$store.state.custom.end_date +
            ").csv",
        },
      },
      excel_fields: {
        details: {
          labels: {
            project_name: "Projects",
            name: "Members",
            date: "Date",
            task: "Task",
            time_worked: "Hours Worked",
            description: "Description",
          },

          fields: [
            "project_name",
            "name",
            "date",
            "task",
            "time_worked",
            "description",
          ],

          dataFile:
            "reports (" +
            this.$store.state.custom.start_date +
            " - " +
            this.$store.state.custom.end_date +
            ").xls",
        },
        members: {
          labels: {
            name: "Members",
            time_worked: "Hours Worked",
          },
          fields: ["name", "time_worked"],
          dataFile:
            "members (" +
            this.$store.state.custom.start_date +
            " - " +
            this.$store.state.custom.end_date +
            ").xls",
        },
        projects: {
          labels: {
            project_name: "Projects",
            time_worked: "Hours Worked",
          },
          fields: ["project_name", "time_worked"],
          dataFile:
            "projects (" +
            this.$store.state.custom.start_date +
            " - " +
            this.$store.state.custom.end_date +
            ").xls",
        },
      },
      filteredData: [],
      selectedMember: "all",
      filteredProjects: [],
      showPicker: false,
      menu: false,
      date: [],
      selectedProject: "all",
      selectedDepartment: "all",
      departmentList: "",
      reports: [],
    };
  },
  computed: {
    ...mapState("custom", [
      "projects_list",
      "team_members",
      "selected_company",
      "reportsByMembers",
      "reportsByProjects",
      "report_member_id",
      "companies_list",
      "lead_team_members",
      "get_team_members_list",
      "projects_list",
    ]),
    ...mapState("auth", ["user"]),
    propMember() {
      if (this.dataForReport) {
        let member = [];
        member.push({
          name: this.dataForReport.search_name,
          id: this.dataForReport.search_member,
        });
        return member;
      }
    },
    /**
     * Return the status of
     * is team lead
     */
    teamLeadStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.has_team;
    },
    selectedCompanyUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.user_status;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    reportsUserStatus() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) {
        if (company.user_status == "employer") {
          return true;
        } else {
          return company.permission.reports;
        }
      } else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },

    csvDownload() {
      let dataFile =
        "reports (" +
        this.$store.state.custom.start_date +
        " - " +
        this.$store.state.custom.end_date +
        ").csv";
      if (this.tabIndex === 0) {
        return {
          data: this.detailsJson,
          name: dataFile,
          labels: this.csv_fields.details.labels,
          fields: this.csv_fields.details.fields,
        };
      }
    },
    excelDownload() {
      let dataFile =
        "reports (" +
        this.$store.state.custom.start_date +
        " - " +
        this.$store.state.custom.end_date +
        ").xls";
      if (this.tabIndex === 0) {
        return {
          data: this.detailsJson,
          name: dataFile,
          labels: this.excel_fields.details.labels,
          fields: this.excel_fields.details.fields,
        };
      }
    },
    accumulatedCsvDownload() {
      let dataFile =
        "reports (" +
        this.$store.state.custom.start_date +
        " - " +
        this.$store.state.custom.end_date +
        ").csv";
      if (this.tabIndex === 0) {
        return {
          data: this.detailsAccumulatedJson,
          name: dataFile,
          labels: {
            name: "Members",
            member_total_time: "Hours Worked",
          },
          fields: ["name", "member_total_time"],
        };
      }
    },
    accumulatedExcelDownload() {
      let dataFile =
        "reports (" +
        this.$store.state.custom.start_date +
        " - " +
        this.$store.state.custom.end_date +
        ").xls";

      if (this.tabIndex === 0) {
        return {
          data: this.detailsAccumulatedJson,
          name: dataFile,
          labels: {
            name: "Members",
            member_total_time: "Hours Worked",
          },
          fields: {
            name: "name",
            "Member Total Time": "member_total_time",
          },
        };
      }
    },
    detailsJson: function () {
      let rawData = this.reports;
      let json = [];
      Object.keys(rawData).forEach((project) => {
        Object.keys(rawData[project].members).forEach((member) => {
          Object.keys(rawData[project].members[member]).forEach((smember) => {
            if (smember === "dates") {
              Object.keys(rawData[project].members[member].dates).forEach(
                (date) => {
                  rawData[project].members[member].dates[date].tasks.forEach(
                    (task) => {
                      let pro = {};
                      pro.project_name = project;
                      pro.name = rawData[project].members[member].name;
                      pro.date = date;
                      pro.task = task.task;
                      pro.description = task.description;
                      pro.time_worked = this.min_to_hour(task.time_worked);
                      json.push(pro);
                    }
                  );
                }
              );
            }
          });
        });
      });
      return json;
    },
    detailsAccumulatedJson: function () {
      let rawData = this.reports;
      let members = {};

      Object.values(rawData)
        .map((p) => p.members)
        .forEach((m) => {
          Object.keys(m).forEach((k) => {
            if (k in members) {
              members[k].member_total_time += m[k].member_total_time;
            } else {
              members[k] = Object.assign({}, m[k]);
            }
          });
        });

      return Object.values(members)
        .map((m) => {
          m.member_total_time = this.min_to_hour(m.member_total_time);
          return m;
        })
        .sort((a, b) => {
          const x = a.member_total_time;
          const y = b.member_total_time;
          return x < y ? 1 : x > y ? -1 : 0;
        });
    },
    getData() {
      return this.$store.state.custom.search_image;
    },
    selectedCompanyUserHasTeam() {
      const company = this.companies_list.find(
        (c) => c.id === this.$route.params.id
      );
      if (company) return company.has_team;
      else {
        this.$store.dispatch("custom/redirectToCompaniesListing");
      }
    },
    showPermission() {
      let permission = this.$store.state.custom.selected_company.permission;
      if (this.selectedCompanyUserStatus === "secondary_user") {
        if (permission.reports) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    },
    /**
     * Return the custom list of
     * projects
     */
    sortProjects() {
      let newProject = [];
      let allProject = {
        id: "all",
        project_name: "All Projects",
      };
      newProject.push(allProject);
      this.projects_list.active.map((project) => {
        newProject.push(project);
      });
      return newProject;
    },
    /**
     * Return the custom list of
     * team members
     */
    teamMember() {
      let newArray = [];
      this.filteredData.map((member) => {
        newArray.push(member);
      });
      return newArray;
    },
    /**
     * Return the custom
     * deparment list
     */
    allDepartmentList() {
      let arrayDep = [];
      let allTeamMembers = {
        hash_id: "all",
        name: "All Departments",
      };
      arrayDep.push(allTeamMembers);
      if (this.departmentList.length > 0) {
        this.departmentList.map((dept) => {
          arrayDep.push(dept);
        });
      }
      return arrayDep;
    },
  },
  watch: {
    selectedProject: function (val) {
      if (this.filteredData.length) {
        this.$store.commit("custom/set_selected_project", val);
      }
    },
    projects_list: function () {
      this.myProjects = this.projects_list.active;
    },
  },
  mounted() {
    this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
    this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
    window.addEventListener("click", () => {
      let x = document.getElementById("custom-list-style");
      if (x && window.getComputedStyle(x).display != "none") {
        document.getElementById("rangeBtn").click();
        x.style.display = "none";
      }
    });
    if (
      this.selectedCompanyUserHasTeam ||
      (this.selectedCompanyUserStatus !== "employee" && this.showPermission)
    ) {
      this.filteredData = [{ id: null, name: "loading..." }];
      this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      this.selectedDateTeamMembers();
    } else {
      this.selectedMember = this.user.id;
    }
    this.dateRangeTitle = "Yesterday";
    if (
      (!this.$store.state.custom.departments ||
        this.$store.state.custom.departments.length == 0) &&
      this.selectedCompanyUserStatus !== "employee"
    ) {
      this.get_department_list();
    } else {
      this.departmentList = this.$store.state.custom.departments;
    }
    if (this.selectedCompanyUserStatus == "employee") {
      this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      this.getReportsData();
    }
    if (this.$store.state.custom.projects_list.active.length == 0) {
      this.getProjects();
    }
    if (this.$store.state.custom.report_member_id != "") {
      this.selectedMember = this.$store.state.custom.report_member_id;
      this.$store.commit("custom/set_report_member_id", "");
      this.getReportsData();
    }
  },
  methods: {
    /*
      Fetch the lastest working members
      data according to given date
     */
    selectedDateTeamMembers() {
      if (
        (this.selectedCompanyUserHasTeam && this.reportsUserStatus) ||
        (this.selectedCompanyUserStatus !== "employee" &&
          this.reportsUserStatus)
      ) {
        let data = {
          company_id: this.$route.params.id,
          start_date: this.start_date,
          end_date: this.end_date,
        };
        this.teamMemberLoader = true;
        this.$store
          .dispatch("custom/getLastWorkingDaysMember", data)
          .then((response) => {
            this.teamMemberLoader = false;
            this.filteredData = response.data.data.team_members;
            if (this.filteredData[0].id != "all") {
              let allMember = {
                id: "all",
                name: "All Members",
              };
              this.filteredData.unshift(allMember);
            }
            this.teamMemberLoader = false;
          })
          .catch(() => {
            this.teamMemberLoader = false;
          });
      }
    },
    /**
     * This function is resposible for
     * fetching the projects list
     */
    getProjects() {
      this.$store.dispatch("custom/getProjects", {
        company_id: this.$route.params.id,
      });
    },
    closeDateModal() {
      this.modal_2 = false;
      this.menu = false;
    },
    checkClick() {
      let x = document.getElementById("custom-list-style");
      if (x && window.getComputedStyle(x).display != "block") {
        x.style.display = "block";
      }
    },
    /**
     * This function is resposible for
     * convert minutes to hour
     */
    min_to_hour(minutes) {
      let time = min_to_hour(minutes);
      let hrs = time.hrs;
      let mins = time.mins;
      if (hrs <= 9) {
        hrs = "0" + time.hrs;
      }
      if (mins <= 9) {
        mins = "0" + time.mins;
      }
      return hrs + ":" + mins;
    },
    /**
     * This function is resposible for
     * fetching reports data
     */
    getReportsData() {
      let data = {
        start_date: this.start_date,
        end_date: this.end_date,
        member_id: this.selectedMember,
        project_id: this.selectedProject,
        company_id: this.$route.params.id,
        department_id: this.selectedDepartment,
        timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
      };
      this.$store
        .dispatch("custom/get_report_data", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          if (response.data.error === false) {
            this.reports = response.data.reports;
          }
        })
        .catch(() => {
          this.$store.commit("custom/toggle_loader", false);
          this.reports = [];
        });
    },
    /**
     * This function is resposible for
     * get report w.r.t custom date
     */
    getreportFromCustomDate() {
      this.modal_2 = false;
      this.start_date = moment(this.date[0]).format("YYYY-MM-DD");
      this.end_date = moment(this.date[1]).format("YYYY-MM-DD");
      if (this.start_date.toString() === "Invalid date") {
        let date = this.end_date.split("-");
        let year = date[0];
        let month = date[1];
        this.start_date = year + "-" + month + "-01";
        this.date[0] = year + "-" + month + "-01";
      }
      this.dateRangeTitle =
        moment(this.date[0]).format("ll") +
        " - " +
        moment(this.date[1]).format("ll");
      if (this.start_date > this.end_date) {
        // this.checkIfStartDateGreater();
        this.dateRangeTitle =
          moment(this.date[1]).format("ll") +
          " - " +
          moment(this.date[0]).format("ll");
      }
      if (this.end_date < this.start_date) {
        this.start_date = this.start_date
          ? this.start_date
          : moment(this.date[1]).format("YYYY-MM-DD");
        this.end_date = moment(this.date[0]).format("YYYY-MM-DD");
      } else if (this.start_date > this.end_date) {
        // this.checkIfStartDateGreater();
      }
      if (
        this.selectedCompanyUserStatus === "employee" &&
        !this.selectedCompanyUserHasTeam
      ) {
        this.memberChanged(this.date);
      }
      this.date = "";
      this.menu = false;
    },
    /**
     * This function is resposible for
     * helper for date selection
     */
    setDates(date) {
      if (date.id === 1) {
        this.start_date = moment().format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 2) {
        this.start_date = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.end_date = moment().subtract(1, "days").format("YYYY-MM-DD");
      } else if (date.id === 3) {
        this.start_date = moment().subtract(6, "days").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 4) {
        this.start_date = moment().subtract(29, "days").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      } else if (date.id === 5) {
        this.start_date = moment().startOf("month").format("YYYY-MM-DD");
        this.end_date = moment().endOf("month").format("YYYY-MM-DD");
      } else if (date.id === 6) {
        this.start_date = moment()
          .subtract(1, "month")
          .startOf("month")
          .format("YYYY-MM-DD");
        this.end_date = moment()
          .subtract(1, "month")
          .endOf("month")
          .format("YYYY-MM-DD");
      } else if (date.id === 7) {
        this.start_date = moment().subtract(1, "year").format("YYYY-MM-DD");
        this.end_date = moment().format("YYYY-MM-DD");
      }
    },
    /**
     * This function is resposible for
     * getting deparment list
     */
    get_department_list() {
      let data = {
        company_id: this.$route.params.id,
      };
      this.$store
        .dispatch("custom/getDeaprtmentList", data)
        .then((response) => {
          this.$store.commit("custom/toggle_loader", false);
          this.departmentList = _.orderBy(
            response.data.department,
            ["name"],
            ["asc"]
          );
          this.$store.commit("custom/set_departments", this.departmentList);
        })
        .catch((error) => {
          this.$store.commit("custom/toggle_loader", false);
          if (error.response) {
            if (error.response.data.message === "Unauthenticated.") {
              this.$store.commit("custom/resetState");
              this.$store.dispatch("auth/reset").then(() => {
                return this.$router.push({ name: "login" });
              });
            }
          }
        });
    },
    /**
     * This function is resposible for
     * custom date range picker
     */
    checkIfCustomDate(date) {
      document.getElementById("cRange").click();
      date.value =
        moment().subtract(1, "days").format("MMM D, YYYY") +
        "-" +
        moment().subtract(1, "days").format("MMM D, YYYY");
      this.dateRangeTitle = date.value = "Yesterday";
      if (date.id === 1) {
        let start = moment().format("YYYY-MM-DD");
        let end = moment().format("YYYY-MM-DD");
        this.dateRangeTitle = "Today";
      }
      if (date.id === 2) {
        let start = moment().subtract(1, "days").format("YYYY-MM-DD");
        let end = moment().subtract(1, "days").format("YYYY-MM-DD");
        this.dateRangeTitle = "Yesterday";
      }
      if (date.id === 3) {
        date.value =
          moment().subtract(6, "days").format("MMM D, YYYY") +
          "-" +
          moment().format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 4) {
        date.value =
          moment().subtract(29, "days").format("MMM D, YYYY") +
          "-" +
          moment().format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 5) {
        date.value =
          moment().startOf("month").format("MMM D, YYYY") +
          "-" +
          moment().endOf("month").format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 6) {
        date.value =
          moment().subtract(1, "month").startOf("month").format("MMM D, YYYY") +
          "-" +
          moment().subtract(1, "month").endOf("month").format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 7) {
        date.value =
          moment().subtract(1, "year").format("MMM D, YYYY") +
          "-" +
          moment().format("MMM D, YYYY");
        this.dateRangeTitle = date.value;
      }
      if (date.id === 8) {
        this.modal_2 = true;
      } else {
        this.showPicker = false;
        this.setDates(date);
        this.selectedDateTeamMembers();
        if (
          this.selectedCompanyUserStatus === "employee" &&
          !this.selectedCompanyUserHasTeam
        ) {
          this.memberChanged(this.start_date, this.end_date);
        }
      }
    },
    get_my_projects() {
      if (this.selectedMember === "all") {
        this.myProjects = this.projects_list.active;
      } else {
        let member = this.team_members.find(
          (member) => this.selectedMember === member.id && member.projects
        );
        if (member) this.myProjects = member.projects;
      }
    },
    memberChanged() {
      this.get_my_projects();
      if (!this.selectedMember) return;
      this.$eventBus.$emit("getReportsData", {
        start_date: this.start_date,
        end_date: this.end_date,
        member_id: this.selectedMember,
        project_id: this.selectedProject,
        company_id: this.$route.params.id,
        department_id: this.department,
      });
    },
  },
};
</script>
<style scoped>
.error-img {
  height: 250px;
  width: 300px;
  margin-top: 30px;
}
.error_text {
  font-size: larger;
  font-weight: 500;
  color: #a9bcdb;
  margin-top: 20px;
}
.selected-date {
  border: 1px solid #e9f1fe !important;
  margin-top: -10px !important;
  font-size: 12px !important;
  display: flex !important;
  height: 40px !important;
  padding: 9px !important;
  border-radius: 3px;
  background-color: #fff;
}
.active-custom-btn {
  background-color: #2758f6 !important;
  color: #fff !important;
}
.break {
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -o-pre-wrap;
  word-wrap: break-word;
}
.border {
  padding: 0px 50px;
  border: unset !important;
  border-radius: 3px !important;
  box-shadow: 0 0 10px 0 rgba(174, 174, 174, 0.4) !important;
}
.main-reports {
  border-radius: 8px;
  box-shadow: 0 25px 35px -20px rgba(172, 184, 207, 0.34);
  background-color: #ffffff;
  color: #36454f;
}
.row.btn-csv-r {
  padding-left: 10px;
}
</style>
